.ql-snow.ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}
.ql-editor {
    overflow-y: scroll;
    resize: vertical;
    min-height: 20vh;
    max-height: 20vh;
    height: 20vh !important;
}

.ql-editor p{   
    margin-bottom: 10px;
}
  
.ql-container {
    min-height: 20vh;
    max-height: 20vh;
    height: 20vh !important;
}

.ql-editor li{
   margin-bottom: 15px;
}


